import Footer from "../components/Footer";
import { Icon } from "@iconify/react";

const SemnesiSimptome = () => {
  return (
    <div className="w-full container-align-t">
      <div className="container mx-auto text-center">
        <h1 className="h1">
          70-75% dintre cazuri sunt diagnosticate în stadii inoperabile
          <sup>3</sup>.
        </h1>
      </div>
      <div className="relative image-container left">
        <div className="absolute hidden -translate-x-1/2 -translate-y-1/2 lg:block left-1/2 top-1/2 animate-bounce">
          <Icon icon="bx:chevron-down" className="text-[70px] text-main-400" />
        </div>
        <img
          src="/images/semne.png"
          alt="Fumatul"
          className="hidden w-7/12 lg:block"
        />
        <img
          src="/images/semne-mobile.png"
          alt="Fumatul"
          className="block w-full lg:hidden"
        />

        <div className="container bottom-0 flex justify-end mx-auto text-center lg:text-right lg:absolute lg:-translate-x-1/2 lg:left-1/2">
          <div className=" box-rounded-1 w-[500px] text-[#2b292b]">
            <div className="w-10/12 py-10 mx-auto space-y-3">
              <p className="text-xl lg:text-[25px] 2xl:text-[30px] italic">
                Marea provocare a cancerului bronho-pulmonar este că acesta nu
                generează semne sau simptome în stadiile incipiente<sup>1</sup>.
                Plămânii nu au receptori pentru durere așa că, în primele faze
                ale bolii, durerea este rară. Simptomele apar tardiv și
                majoritatea cazurilor sunt deja inoperabile în momentul
                diagnosticării. Așa se explică rata ridicată a mortalității prin
                cancer bronho-pulmonar.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full px-8 lg:px-0">
        <div className="container mx-auto pt-11">
          <div className="w-full max-w-[900px] 2xl:max-w-[1090px] mx-auto">
            <div className="items-center lg:flex">
              <div className="w-full py-6 lg:border-black lg:w-5/12 2xl:w-3/12 lg:border-t-2">
                <h3 className="h3 text-main-600">
                  Observați-vă corpul cu atenție, încercați să identificați dacă
                  vă transmite oricare dintre aceste semne<sup>1,4</sup> care,
                  în mod frecvent, sunt semnale de alarmă pentru cancerul de
                  plămân:
                </h3>
              </div>
              <div className="w-full lg:w-8/12 2xl:w-9/12 ">
                <img
                  src="/images/bull.png"
                  alt="Fumatul"
                  className="hidden lg:block"
                />
                <img
                  src="/images/buline-mobile-1.png"
                  alt="Fumatul"
                  className=" lg:hidden"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-4 mx-auto ">
          <div className="w-full max-w-[900px] 2xl:max-w-[1090px] mx-auto">
            <div className="w-full py-6 mx-auto space-y-6 border-black lg:space-y-0 lg:border-t-2 lg:border-b-2 lg:flex tems-center">
              <div className="w-full lg:w-6/12">
                <h3 className="h3 text-main-600">
                  Există și alte simptome la care
                  <br /> trebuie să fim atenți, chiar dacă
                  <br />
                  ele sunt mai puțin specifice<sup>4</sup>:
                </h3>
              </div>
              <div className="w-full lg:w-6/12">
                <img
                  src="/images/bull2.png"
                  alt="Fumatul"
                  className="hidden lg:block"
                />
                <img
                  src="/images/buline-mobile-2.png"
                  alt="Fumatul"
                  className=" lg:hidden"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container pt-4 mx-auto ">
          <div className="w-full max-w-[900px] 2xl:max-w-[1090px] mx-auto">
            <div className="flex w-full py-6 mx-auto">
              <p className="font-s-italic text-xl 2xl:text-[35px] leading-normal">
                Dacă observați oricare dintre aceste semne sau simptome și ele
                sunt persistente, mergeți la medicul dumneavoastră. Să nu uităm
                că ele pot fi provocate și de alte afecțiuni, nu doar de
                cancerul bronho-pulmonar, dar este întotdeauna mai bine să
                previi decât să tratezi.
              </p>
            </div>
          </div>
        </div>

        <div className="container mx-auto pt-11">
          <div className="w-full max-w-[900px] 2xl:max-w-[1090px] mx-auto">
            <p className="text-xs lg:text-sm">
              <sup>1</sup>Bristol-Myers Squibb. (n.d). Cancerul Pulmonar:
              Diagnostic și Tratament, Un Ghid Pentru Pacienți și Aparținători;
              <br />
              <sup>3</sup>Goeckenjan G. et al. Prävention, Diagnostik, Therapie
              und Nachsorge des Lungenkarzinoms Interdisziplinäre S3-Leitlinie
              der Deutschen Gesellschaft für Pneumologie und Beatmungsmedizin
              und der Deutschen Krebsgesellschaft.
              <br />
              <sup>4</sup>European Society for Medical Oncology. (2019).
              Cancerul de Plămân Fără Celule Mici: Ghidul pentru Pacienți
              [Online] Disponibil la
              https://www.esmo.org/content/download/303704/6040931/1/RO-Cancerul-de-Plaman-Fara-Celule-Mici-Ghidul-pentru-Pacienti.pdf
              [Accesat la 18.07.2022]
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default SemnesiSimptome;
