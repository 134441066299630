import { useState } from "react";
import Footer from "../components/Footer";

const TestRisc = () => {
  const [q1, setQ1] = useState(0);
  const [q2, setQ2] = useState(0);
  const [q3, setQ3] = useState(0);
  const [q4, setQ4] = useState(0);
  const [q5, setQ5] = useState(0);
  const [q6, setQ6] = useState(0);
  const [q7, setQ7] = useState(0);
  const [answerText, setAnswerText] = useState(
    "Ai răspuns pozitiv la una dintre întrebările din testul de mai sus. Tusea este unul dintre cele mai frecvente semne pe care corpul ți le transmite când ceva nu e în regulă. O tuse persistentă nu înseamnă un diagnostic de cancer pulmonar. Ea poate avea și cauze benigne și poate semnala simptomul altor afecțiuni. Există și alte simptome la care trebuie să fim atenți, chiar dacă ele sunt mai puțin specifice: febră, pierderea poftei de mâncare, scădere inexplicabilă în greutate, senzație de oboseală excesivă. Dar e întotdeauna mai puțin dureros să previi decât să tratezi. "
  );
  const [hasAnswer, setHasAnswer] = useState(false);
  return (
    <div className="w-full px-8 lg:px-0 lg:pt-0 ">
      <div className="container pt-20 mx-auto text-center">
        <h1 className="h1">TEST RISC</h1>
      </div>
      <div className="container pt-20 mx-auto text-center">
        <div className="w-full max-w-[900px] 2xl:max-w-[1090px] mx-auto">
          <ul className="list-question">
            <li>
              <p>1. Fumezi de peste 10 ani?</p>
              <div>
                <label>
                  <input
                    type="radio"
                    name="q1"
                    value="1"
                    onChange={() => {
                      setQ1(1);
                    }}
                  />
                  <p>Da</p>
                </label>
                <label>
                  <input
                    type="radio"
                    name="q1"
                    value="0"
                    onChange={() => {
                      setQ1(0);
                    }}
                  />
                  <p>Nu</p>
                </label>
              </div>
            </li>
            <li>
              <p>
                2. Trăiești într-o zona poluată, cu trafic extrem de aglomerat?
              </p>
              <div>
                <label>
                  <input
                    type="radio"
                    name="q2"
                    value="1"
                    onChange={() => {
                      setQ2(1);
                    }}
                  />
                  <p>Da</p>
                </label>
                <label>
                  <input
                    type="radio"
                    name="q2"
                    value="0"
                    onChange={() => {
                      setQ2(0);
                    }}
                  />
                  <p>Nu</p>
                </label>
              </div>
            </li>
            <li>
              <p>
                3. Trăiești în preajma unei alte surse de poluare, uzine,
                fabrici, altele?
              </p>
              <div>
                <label>
                  <input
                    type="radio"
                    name="q3"
                    value="1"
                    onChange={() => {
                      setQ3(1);
                    }}
                  />
                  <p>Da</p>
                </label>
                <label>
                  <input
                    type="radio"
                    name="q3"
                    value="0"
                    onChange={() => {
                      setQ3(0);
                    }}
                  />
                  <p>Nu</p>
                </label>
              </div>
            </li>
            <li>
              <p>
                4. Îți încălzești locuința sau prepari mâncarea cu lemne sau gaz
                sau cărbuni sau alt combustibil, care generează fum foarte mult?
              </p>
              <div>
                <label>
                  <input
                    type="radio"
                    name="q4"
                    value="1"
                    onChange={() => {
                      setQ4(1);
                    }}
                  />
                  <p>Da</p>
                </label>
                <label>
                  <input
                    type="radio"
                    name="q4"
                    value="0"
                    onChange={() => {
                      setQ4(0);
                    }}
                  />
                  <p>Nu</p>
                </label>
              </div>
            </li>
            <li>
              <p>5. Ai peste 40 de ani?</p>
              <div>
                <label>
                  <input
                    type="radio"
                    name="q5"
                    value="1"
                    onChange={() => {
                      setQ5(1);
                    }}
                  />
                  <p>Da</p>
                </label>
                <label>
                  <input
                    type="radio"
                    name="q5"
                    value="0"
                    onChange={() => {
                      setQ5(0);
                    }}
                  />
                  <p>Nu</p>
                </label>
              </div>
            </li>
            <li>
              <p>
                6. Ai sau ai avut o profesie cu risc mare de cancer (ex: cu
                expunere la azbest, siliciu, cocs, crom, nichel, beriliu,
                pesticide, radon, radiații ionizante, pulberi diverse -
                construcții)?
              </p>
              <div>
                <label>
                  <input
                    type="radio"
                    name="q6"
                    value="1"
                    onChange={() => {
                      setQ6(1);
                    }}
                  />
                  <p>Da</p>
                </label>
                <label>
                  <input
                    type="radio"
                    name="q6"
                    value="0"
                    onChange={() => {
                      setQ6(0);
                    }}
                  />
                  <p>Nu</p>
                </label>
              </div>
            </li>
            <li>
              <p>
                7. Ai avut sau ai în familie rude de gradul I cu cancer pulmonar
                sau alte cancere?
              </p>
              <div>
                <label>
                  <input
                    type="radio"
                    name="q7"
                    value="1"
                    onChange={() => {
                      setQ7(1);
                    }}
                  />
                  <p>Da</p>
                </label>
                <label>
                  <input
                    type="radio"
                    name="q7"
                    value="0"
                    onChange={() => {
                      setQ7(0);
                    }}
                  />
                  <p>Nu</p>
                </label>
              </div>
            </li>
            <li className="relatve">
              <div className="relative w-fit">
                <button
                  type="button"
                  onClick={() => {
                    const total = q1 + q2 + q3 + q4 + q5 + q6 + q7;
                    if (total === 0) {
                      setAnswerText("Nu ai completat niciun răspuns. ");
                    }
                    if (total === 1) {
                      setAnswerText(
                        "Ai răspuns pozitiv la una dintre întrebările din testul de mai sus. Tusea este unul dintre cele mai frecvente semne pe care corpul ți le transmite când ceva nu e în regulă. O tuse persistentă nu înseamnă un diagnostic de cancer pulmonar. Ea poate avea și cauze benigne și poate semnala simptomul altor afecțiuni. Există și alte simptome la care trebuie să fim atenți, chiar dacă ele sunt mai puțin specifice: febră, pierderea poftei de mâncare, scădere inexplicabilă în greutate, senzație de oboseală excesivă. Dar e întotdeauna mai puțin dureros să previi decât să tratezi. "
                      );
                    }
                    if (total === 2) {
                      setAnswerText(
                        "Ai răspuns pozitiv la două dintre întrebările din testul de mai sus. Tusea este unul dintre cele mai frecvente semne pe care corpul ți le transmite când ceva nu e în regulă. O tuse persistentă nu înseamnă un diagnostic de cancer pulmonar. Ea poate avea și cauze benigne și poate semnala simptomul altor afecțiuni. Există și alte simptome la care trebuie să fim atenți, chiar dacă ele sunt mai puțin specifice: febră, pierderea poftei de mâncare, scădere inexplicabilă în greutate, senzație de oboseală excesivă. Dar e întotdeauna mai puțin dureros să previi decât să tratezi. "
                      );
                    }
                    if (total === 3) {
                      setAnswerText(
                        "Ai răspuns pozitiv la trei dintre întrebările din testul de mai sus. Tusea este unul dintre cele mai frecvente semne pe care corpul ți le transmite când ceva nu e în regulă. O tuse persistentă nu înseamnă un diagnostic de cancer pulmonar. Ea poate avea și cauze benigne și poate semnala simptomul altor afecțiuni. Există și alte simptome la care trebuie să fim atenți, chiar dacă ele sunt mai puțin specifice: febră, pierderea poftei de mâncare, scădere inexplicabilă în greutate, senzație de oboseală excesivă. Dar e întotdeauna mai puțin dureros să previi decât să tratezi. "
                      );
                    }
                    if (total === 4) {
                      setAnswerText(
                        "Ai răspuns pozitiv la patru dintre întrebările din testul de mai sus. Tusea este unul dintre cele mai frecvente semne pe care corpul ți le transmite când ceva nu e în regulă. O tuse persistentă nu înseamnă un diagnostic de cancer pulmonar. Ea poate avea și cauze benigne și poate semnala simptomul altor afecțiuni. Există și alte simptome la care trebuie să fim atenți, chiar dacă ele sunt mai puțin specifice: febră, pierderea poftei de mâncare, scădere inexplicabilă în greutate, senzație de oboseală excesivă. Dar e întotdeauna mai puțin dureros să previi decât să tratezi."
                      );
                    }
                    if (total === 5) {
                      setAnswerText(
                        "Ai răspuns pozitiv la cinci dintre întrebările din testul de mai sus. Tusea este unul dintre cele mai frecvente semne pe care corpul ți le transmite când ceva nu e în regulă. O tuse persistentă nu înseamnă un diagnostic de cancer pulmonar. Ea poate avea și cauze benigne și poate semnala simptomul altor afecțiuni. Există și alte simptome la care trebuie să fim atenți, chiar dacă ele sunt mai puțin specifice: febră, pierderea poftei de mâncare, scădere inexplicabilă în greutate, senzație de oboseală excesivă. Dar e întotdeauna mai puțin dureros să previi decât să tratezi. "
                      );
                    }
                    if (total === 6) {
                      setAnswerText(
                        "Ai răspuns pozitiv la șase dintre întrebările din testul de mai sus. Tusea este unul dintre cele mai frecvente semne pe care corpul ți le transmite când ceva nu e în regulă. O tuse persistentă nu înseamnă un diagnostic de cancer pulmonar. Ea poate avea și cauze benigne și poate semnala simptomul altor afecțiuni. Există și alte simptome la care trebuie să fim atenți, chiar dacă ele sunt mai puțin specifice: febră, pierderea poftei de mâncare, scădere inexplicabilă în greutate, senzație de oboseală excesivă. Dar e întotdeauna mai puțin dureros să previi decât să tratezi. "
                      );
                    }
                    if (total === 7) {
                      setAnswerText(
                        "Ai răspuns pozitiv la șapte dintre întrebările din testul de mai sus. Tusea este unul dintre cele mai frecvente semne pe care corpul ți le transmite când ceva nu e în regulă. O tuse persistentă nu înseamnă un diagnostic de cancer pulmonar. Ea poate avea și cauze benigne și poate semnala simptomul altor afecțiuni. Există și alte simptome la care trebuie să fim atenți, chiar dacă ele sunt mai puțin specifice: febră, pierderea poftei de mâncare, scădere inexplicabilă în greutate, senzație de oboseală excesivă. Dar e întotdeauna mai puțin dureros să previi decât să tratezi. "
                      );
                    }
                    setHasAnswer(true);
                  }}
                  className="px-6 py-2 text-white border-0 bg-main-500 rounded-xl"
                >
                  Vezi rezultate
                </button>
                <img
                  src="/images/arrow.svg"
                  alt=""
                  className={`absolute top-10 -right-10  ${
                    hasAnswer ? "opacity-100" : "opacity-0"
                  } `}
                />
              </div>
            </li>
          </ul>
          <div
            className={`container lg:ml-[360px] mt-20 max-w-[1090px] ${
              hasAnswer ? "opacity-100" : "opacity-0"
            }`}
          >
            <div className="text-white bg-main-400 rounded-[70px] max-w-[900px] p-8 lg:p-20 rounded-tl-none">
              {answerText}
              <br />
              <br />
              <p className="text-xl italic bold">
                Dacă ai oricare dintre aceste simptome și ele sunt persistente,
                mergi la medic!
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default TestRisc;
