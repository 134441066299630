import { Routes, Route, Link } from "react-router-dom";
import { useState } from "react";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className="h-[69px] lg:h-auto relative z-50 w-full">
      <Link to="/" className="absolute top-[20px] left-[20px] z-50">
        <img
          src="/images/logo.png"
          alt="logo"
          className={`w-[120px] ${isOpen ? "w-[80px]" : "w-[120px]"}`}
        />
      </Link>
      <div
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="absolute top-0 right-0 rounded-bl-2xl z-50 flex items-center justify-center bg-main-500 lg:hidden w-[80px] h-[70px]"
      >
        <img src="/images/menu-icon.png" className="w-[33px]" alt="menu" />
      </div>
      <div
        className={`items-center justify-end ${
          !isOpen ? "hidden" : "  absolute top-0 left-0 w-full h-full"
        } lg:flex  `}
      >
        <div className="header-info">
          <ul>
            <li>
              <Link
                onClick={() => {
                  setIsOpen(false);
                }}
                to="/cum-actioneaza"
              >
                Cum acționează cancerul
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setIsOpen(false);
                }}
                className="hidden-on-mobile"
                to="/ce-spun-semnele"
              >
                Ce spun semnele
              </Link>
              <ul>
                <li className="">
                  <Link
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    to="/ce-spun-semnele"
                  >
                    Factori de risc
                  </Link>
                </li>
                <li>
                  <Link
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    to="/semne-si-simptome"
                  >
                    Semne și simptome
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                onClick={() => {
                  setIsOpen(false);
                }}
                to="/ce-spune-tusea"
              >
                Ce spune tusea
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setIsOpen(false);
                }}
                to="/ce-spune-medicul"
              >
                Ce spune medicul
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setIsOpen(false);
                }}
                to="/fa-ti-un-plan"
              >
                Fă-ți un plan
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setIsOpen(false);
                }}
                to="/asculta"
              >
                Ascultă
              </Link>
            </li>
            <li>
              <Link
                onClick={() => {
                  setIsOpen(false);
                }}
                to="/test-risc"
                className="last"
              >
                Test risc
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default Header;
