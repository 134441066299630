import { Routes, Route, Link } from "react-router-dom";
import Home from "./pages/Home";
import CeSpunSemnele from "./pages/CeSpunSemnele";
import Asculta from "./pages/Asculta";
import Header from "./components/Header";
import "./App.css";
import CeSpuneMedicul from "./pages/CeSpuneMedicul";
import CeSpuneTusea from "./pages/CeSpuneTusea";
import CumActioneaza from "./pages/CumActioneaza";
import FatiUnPlan from "./pages/FatiUnPlan";
import SemnesiSimptome from "./pages/SemnesiSimptome";
import TestRisc from "./pages/TestRisc";

function App() {
  return (
    <div className="w-full  App">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ce-spun-semnele" element={<CeSpunSemnele />} />
        <Route path="/fa-ti-un-plan" element={<FatiUnPlan />} />
        <Route path="/asculta" element={<Asculta />} />
        <Route path="/ce-spune-medicul" element={<CeSpuneMedicul />} />
        <Route path="/ce-spune-tusea" element={<CeSpuneTusea />} />
        <Route path="/semne-si-simptome" element={<SemnesiSimptome />} />
        <Route path="/test-risc" element={<TestRisc />} />
        <Route path="/cum-actioneaza" element={<CumActioneaza />} />
      </Routes>
    </div>
  );
}

export default App;
