import Footer from "../components/Footer";
import { Icon } from "@iconify/react";

const Asculta = () => {
  return (
    <div className="w-full px-8 container-align-t lg:px-0">
      <div className="container hidden mx-auto lg:block">
        <div className="text-leading-1">ascul</div>
      </div>
      <div className="container justify-between mx-auto lg:flex">
        <div className="w-full mx-auto lg:w-4/12 ">
          <img src="/images/a-mobile.png" className="block mb-6 lg:hidden" />

          <div className="space-y-3 text-left lg:text-right">
            <p className="font-s-italic text-xl 2xl:text-[35px] leading-normal">
              Este important să te implici și împreună cu medicul tău să formați
              o echipă. Comunică cu el, e mai ușor de urmat un tratament atunci
              când înțelegi ce presupune acesta. Nu te teme să pui întrebări,
              oricare ar fie ele. O bună metodă este să notezi întrebările pe
              care le ai și să te duci cu ele pregătite, dar și să iei notițe în
              timpul vizitei medicale. Roagă echipa medicală să îți ofere
              explicații ample și clare. Tipul de tratament care să răspundă
              nevoilor fiecărui pacient, poate fi urmat cu succes numai dacă
              acesta se implică.
            </p>
            <h2 className="pt-6 text-left lg:pt-0 lg:text-right h1">
              Orice simți, este normal.
            </h2>
            <p>
              Uneori, este mai ușor să vorbim cu o persoană care înțelege prin
              ce trecem, precum medicul sau un alt pacient, decât cu cei dragi.
              La aflarea unui diagnostic de cancer primele reacții pot fi de
              furie și frustrare, putem sa ne izolăm din teamă, negare sau
              necunoaștere.
              <br />
              Întotdeauna însă, ceea ce simțim este normal. Este important să ne
              acordam timp, să procesăm un diagnostic, un tratament, o schimbare
              majoră cum este cea cauzată de cancer.
              <br />
              Întotdeauna este important să cerem ajutor, să vorbim cu ceilalți,
              să găsim sprijin în ei. Pentru că niciunul dintre noi nu trebuie
              să treacă singur prin încercarile legate de boală.
            </p>
          </div>
        </div>
        <div className="hidden lg:block line-oblique"></div>
        <div className="w-full mx-auto space-y-3 lg:w-4/12">
          <div className="hidden lg:block text-leading-2">-tă</div>

          <div>
            <img src="/images/a-2.png" className="block mt-12 mb-6 lg:hidden" />
            <h2 className="pt-6 text-left lg:pt-0 h1">
              Sprijin există, fă tu primul pas.
            </h2>
            <p>
              În Europa există o serie de grupuri de sprijin pentru pacienții cu
              cancer de plămân, care ajută pacienții și familiile acestora să
              înțeleagă mai bine provocările specifice aduse de acest tip de
              cancer. Aceste grupuri de sprijin pot fi locale, naționale sau
              internaționale și misiunea lor constă în a se asigura că pacienții
              beneficiază de îngrijiri și informare, adecvate. Aceste grupuri vă
              pot oferi instrumentele necesare pentru a înțelege mai bine boala
              dumneavoastră sau a cuiva drag, pentru a afla cum să faceți față
              situației, cum să mențineți sau să creșteți calitatea vieții în
              lupta cu boala.
              <br />
              <br />
              <strong>
                Puteți accesa informații referitoare la următoarele organizații:
              </strong>
              <br />
              <br />• Federația Asociațiilor Bolnavilor de Cancer:
              https://fabc.ro/ <br />• Global Lung Cancer Coalition (GLCC):
              www.lungcancercoalition.org <br />
              • Lung Cancer Europe (LuCE): www.lungcancereurope.eu <br />
              • Women Against Lung Cancer in Europe (WALCE):
              www.womenagainstlungcancer.eu/?lang=en
              <br />
              <br />
              În cadrul evenimentelor de informare, pacienții și persoanele
              interesate pot afla mai multe despre posibilitățile actuale de
              tratament și despre modurile de îngrijire a bolii. Adesea au
              ocazia să discute si cu experți sau alți pacienți. În cadrul
              acestor grupuri, în prim-plan se află schimbul de experiență și
              sprijinul mutual intre pacienții afectați.
            </p>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
export default Asculta;
