import Logos from "./Logos";

const Footer = () => {
  return (
    <div className="w-full pt-[60px]">
      <div className="w-10/12 mx-auto">
        <div className="line-logo">
          <div className="box-logo">
            <img src="/images/logo.png" alt="Logo" />
          </div>
        </div>
        <div className="pt-6 space-y-3 text-center ">
          <p>
            Pentru mai multe detalii vă rugăm să nu ezitați să ne contactați la
            adresa de e-mail andreea.papa@bms.com
            <br />@ 2022 Asculta tusea. Toate drepturile rezevate
          </p>
          <Logos />
          <p className="text-xs text-center">
            Material aprobat de ANMDMR: 18.11.2022
            <br />
            ONC-RO-2200095
          </p>
        </div>
      </div>
    </div>
  );
};
export default Footer;
